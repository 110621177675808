<template>
  <div class="flex-grow-1">
    <div class="mb-5">
      <h2 class="text-center font-weight-black" :class="$vuetify.breakpoint.xs ? 'text-h4' : 'text-h3'">Find A Job</h2>
    </div>

    <div class="mb-5">
      <v-card color="primary" class="search-bar">
        <v-card-text class="d-flex align-center gap-10" :class="{ 'flex-wrap' : $vuetify.breakpoint.xs }">
          <v-row :dense="$vuetify.breakpoint.xs" align="center">
            <v-col :class="{'py-0' : !$vuetify.breakpoint.xs}" cols="12" md="4">
              <v-select
                v-model="searchPositions"
                :items="orderItems(positions, 'order', 'asc')"
                item-text="name"
                item-value="name"
                label="Position"
                @click:clear="$store.commit('jobSearch/setHits', [])"
                @focus="getPositionList()"
                prepend-icon="mdi-account"
                deletable-chips
                solo-inverted
                hide-details
                small-chips
                multiple
                outlined
                chips
                dense
                dark
              ></v-select>
            </v-col>

            <v-col :class="{'py-0' : !$vuetify.breakpoint.xs}" cols="12" md="5">
              <v-autocomplete
                v-model="searchSkillSets"
                label="Skillset"
                :items="skillset"
                :multiple="skillset.length == 1? false : true"
                @click:clear="$store.commit('jobSearch/setHits', [])"
                @focus="getSkillsetList()"
                :search-input.sync="skillsInput"
                @change="skillsInput = null"
                prepend-icon="mdi-license"
                item-text="name"
                color="white"
                deletable-chips
                solo-inverted
                small-chips
                hide-details
                outlined
                chips
                dense
                dark
              ></v-autocomplete>
            </v-col>

            <v-col :class="{'py-0' : !$vuetify.breakpoint.xs}" cols="12" md="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox
                    v-model="dates"
                    v-bind="attrs"
                    v-on="on"
                    label="Starting from..."
                    prepend-icon="mdi-calendar-month-outline"
                    color="white"
                    hide-details
                    solo-inverted
                    clearable
                    readonly
                    outlined
                    dense
                    dark
                  ></v-combobox>
                </template>
                <v-date-picker
                  v-model="dates"
                  @change="$refs.menu.save(dates)"
                  scrollable
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          
          <v-btn
            @click="search()"
            :block="$vuetify.breakpoint.xs"
            :loading="searching"
            color="accent gradient"
            class="ma-0"
            rounded
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>

        </v-card-text>

      </v-card> 
    </div>

    <div class="mb-5 d-flex align-center gap-20">
      <div>
        <v-sheet max-width="900" color="transparent">
          Enter job details to find local contracts that match your needs. Refine or expand your search using the ‘Refine Search’ filters and change the display of your results using the sort button on the top right.
        </v-sheet>
      </div>

      <v-spacer></v-spacer>

      <div>
        <!-- FILTER JOBS -->
        <v-btn @click="drawer = !drawer" class="rounded mb-3 d-inline-block d-sm-none" icon outlined>
          <v-icon>mdi-filter-menu</v-icon>
        </v-btn>
        
        <!-- ORDER JOBS -->
        <v-menu
          :close-on-content-click="false"
          offset-y
          bottom
          left
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="rounded" icon outlined>
              <v-icon>mdi-sort</v-icon>
            </v-btn>  
          </template>

          <v-card width="200">
            <v-card-text>
              <v-radio-group 
                v-model="order"
                class="mt-0"
                hide-details
              >
                <v-radio label="Created" value="createdAt"></v-radio>
                <v-radio label="Contract Date" value="contract"></v-radio>
                <v-radio label="Saved Jobs" value="saved"></v-radio>
              </v-radio-group>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      </div>

    <!-- Filters -->
    <div>
      <v-navigation-drawer 
        v-if="$vuetify.breakpoint.xs"
        v-model="drawer"
        absolute
      >
        <JobFilter @searched="search"/>
      </v-navigation-drawer>

      <v-row>
        <v-col 
          v-if="!$vuetify.breakpoint.xs" 
          class="d-none d-sm-flex"
          cols="3" 
        >
          <div>
            <JobFilter @searched="search"/>
          </div>
        </v-col>

        <v-col cols="12" md="9" class="jobs-section"> 
          <div class="mb-5">Showing {{ showing }} of {{ refined.length }} {{ pluralize('result', refined.length) }}</div>
          <preloader v-if="status.getting" />
          <div class="text-center" v-if="!refined.length && !status.getting">
              No records found.
          </div>
          <div v-if="refined">
            <JobsCard 
              v-for="job in page"
              :key="job.id"
              :job="job"
            />
          </div>

          <div>
            <v-btn 
              v-if="showing > 5"
              @click="prevPage()"
              class="rounded mr-1"
              depressed
              x-small
              fab
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              v-for="(n, i) in Math.ceil(refined.length/5)"
              :key="i"
              :color="activePage == n ? 'accent' : 'default'"
              @click="
                $store.commit('jobs/setActivePage', n)
                $scrollTop()
              "
              class="rounded mr-1"
              depressed
              x-small
              fab
            >
              <span class="caption">{{ n }}</span>
            </v-btn>
            <v-btn 
              v-if="showing < refined.length" 
              @click="nextPage()"
              class="rounded"
              depressed
              x-small
              fab
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-col>

      </v-row>
    </div>
    <!-- End Filters -->
  </div>
</template>

<script>
import JobsCard from '@/views/job/JobCard'
import JobFilter from '@/components/dashboard/job/FindAJobFilter'
import { mapState, mapActions } from 'vuex'
import firebase from 'firebase'
import _intersection from 'lodash/intersection'
import _slice from 'lodash/slice'
import _orderBy from 'lodash/orderBy'
import orderItems from 'lodash/orderBy'
import pluralize from 'pluralize'
import moment from 'moment'
const geofire = require('geofire-common')

export default {
  name: 'Jobs',

  metaInfo: {
    title: 'Jobs'
  },

   data() {
    return {
      orderItems,
      searchFilterValue: null,
      searchSkillSets: null,
      searchPositions: null,
      order: 'createdAt',
      skillsInput: null,
      searching: false,
      drawer: false,
      dates: null,
      menu: null,
      displayRanges: [
        {
          name: 'Last 60 Days',
          value: 60
        },
        {
          name: 'Last 30 Days',
          value: 30
        },
        {
          name: 'Last 7 Days',
          value: 7
        },
        {
          name: 'All',
          value: 0
        },
      ],

      orderBy: [
        {
          name: 'Newest First',
          value: 'createdAt'
        },
        {
          name: 'Oldest',
          value: 'asc'
        }
      ],
      pluralize,
    }
  },

  components: {
    JobsCard,
    JobFilter,
  },

/* -------------------------------------------------------------------------- */
/*                                  COMPUTED                                  */
/* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.jobs.status,
      activePage: state => state.jobs.status.activePage,
      dataSearch: state => state.jobSearch.data,
      searchStatus: state => state.jobSearch.status,
      skillset: state => state.skillSets.skillSets,
      skillsetStatus: state => state.skillSets.status,
      positions: state => state.position.data,
      positionStatus: state => state.position.status,
      hits: state => state.jobSearch.hits,
      favorites: state => state.jobs.favorites,
    }),

    refined: function () {
      let refined = this.$store.getters['jobs/activeJobs'](true)
      
      refined = refined.filter(job => job.status !== 'Closed')

      // REFINE BY POSITION
      refined = refined.filter(job => {
        return this.dataSearch.position && this.dataSearch.position.length ? _intersection(this.dataSearch.position, job.position).length : true
      })

      // REFINE BY CONTRACT
      if (this.dataSearch.contract.length) {
        refined = refined.filter(job => this.dataSearch.contract.includes(job.contractLength))
      }

      // SCHOOL NAME
      if (this.dataSearch.schoolName) {
        refined = refined.filter(job => {
            let school = this.$store.getters['schools/userSchool'](job.useruid)
            return (school.name.toLowerCase().includes(this.dataSearch.schoolName.toLowerCase()))
        })
      }

      // REFINE REGION
      if (this.dataSearch.region) {
        refined = refined.filter(job => {
          let school = this.$store.getters['schools/userSchool'](job.useruid)

          return school.address 
                 && school.address.administrative_area_level_2 == this.dataSearch.region.administrative_area_level_2
                 && school.address.administrative_area_level_1 == this.dataSearch.region.administrative_area_level_1
                 && school.address.locality == this.dataSearch.region.locality
        })
      }
      else if (this.user && this.user.address) {
        const center = [this.user.address.latitude, this.user.address.longitude]
        
        refined = refined.filter(job => {
          let school = this.$store.getters['schools/userSchool'](job.useruid)

          if (school.address) {
            const distanceInKm = geofire.distanceBetween([school.address.latitude, school.address.longitude], center)
            return distanceInKm <= this.dataSearch.distance
          }
          else {
            return false
          }
        })
      }

      // SCHOOL TYPE
      if (this.dataSearch.schoolType && this.dataSearch.schoolType.length) {
        refined = refined.filter(job => {
          let school = this.$store.getters['schools/userSchool'](job.useruid)
          return this.dataSearch.schoolType.includes(school.type)
        })
      }

      // REFINE BY LOCALE
      if (this.dataSearch.locale.length) {
        refined = refined.filter(job => {
          let school = this.$store.getters['schools/userSchool'](job.useruid)
          return this.dataSearch.locale.includes(school.locale)
        })
      }

      // REFINE BY YEAR LEVEL
      if (this.dataSearch.yearLvl.length) {
        refined = refined.filter(job => this.dataSearch.yearLvl.includes(job.yearLevel))
      }

      // REFINE BY DATES
      if (this.dataSearch.date) {
        refined = refined.filter(job => {
          if (job.contractDates && job.contractDates.length) {
            return moment(this.dataSearch.date).valueOf() == this.$startDate(job.contractDates)
          }
          else {
            return false
          }
        })
      }

      // REFINE BY SKILLSETS
      refined = refined.filter(job => {
        return this.dataSearch.skillsets && this.dataSearch.skillsets.length ? !!_intersection(job.skillSet, this.dataSearch.skillsets).length : true
      })

      // HITS
      if (this.hits.length) {
        refined = refined.filter(job => {
          return !!this.hits.find(h => h.objectID == job.id)
        })
      }

      // ORDERING
      if (this.order == 'createdAt') {
        refined = _orderBy(refined, 'createdAt', 'desc')
      }
      else if (this.order == 'contract') {
        refined = _orderBy(refined, [(job) => {
          if (job.contractDates && job.contractDates.length) {
            let startDate = null

            job.contractDates.forEach((dates, i) => {
              if (i == 0) startDate = moment(dates[0]).valueOf()
              else startDate = moment(dates[0]).valueOf() < startDate ? moment(dates[0]).valueOf() : startDate
            })

            return startDate
          }
          else {
            return 999999999999999
          }
        }], ['asc'])
      }
      else if (this.order == 'saved') {
        refined = refined.filter(job => {
          return !!this.favorites.find(fav => fav.jobId == job.id)
        })
      }

      return refined
    },

    /*------------------------------------------------------------------------------
     * PAGINATION
     *----------------------------------------------------------------------------*/
    page: function () {
      let start = (this.activePage * 5) - 5
      let end = start + 5

      return _slice(this.refined, start, end)
    },

    pages: function () {
      return this.refined.length / 5
    },

    showing: function () {
      return ((this.activePage * 5) - 5) + this.page.length
    },

    showNext: function () {
      let diff = this.refined.length - (this.showing + 5)
      if (diff >= 5) {
        return this.showing + 5
      }
      else {
        return this.refined.length
      }
    }

  },

/* -------------------------------------------------------------------------- */
/*                                    WATCH                                   */
/* -------------------------------------------------------------------------- */
  watch: {
    'user': function(val) {
      if(val) {
        if(val && val.role == 'employer'){
          this.$router.push({ name: 'Talent' }).catch(()=>{})
        }
      }
    },

    'refined': function () {
      this.$store.commit('jobs/setActivePage', 1)
    }
  },
/* -------------------------------------------------------------------------- */
/*                                   METHODS                                  */
/* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions('apply', [
      'getApplied',
    ]),

    ...mapActions('skillSets', [
        'getSkillSets',
    ]),
    
    ...mapActions('position', [
        'getPositions',
    ]),
    
    ...mapActions('jobs', [
        'getAllJobs',
        'searchedJobs',
        'getFavorites',
    ]),

    search() {
      this.$store.commit('jobSearch/setPosition', this.searchPositions)
      this.$store.commit('jobSearch/setSkillsets', this.searchSkillSets)
      this.$store.commit('jobSearch/setDate', this.dates)
    },

    nextPage() {
      let active = this.activePage
      active += 1
      this.$store.commit('jobs/setActivePage', active)
      this.$scrollTop()
    },

    prevPage() {
      let active = this.activePage
      active -= 1
      this.$store.commit('jobs/setActivePage', active)
      this.$scrollTop()
    },

    getSkillsetList() {
      if (!this.skillsetStatus.firstLoad) this.getSkillSets()
    },
    
    getPositionList() {
      if (!this.positionStatus.firstLoad) this.getPositions()
    },

    scrollUp() {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    },

  },

  mounted() {
    if (!this.status.firstLoad) this.getAllJobs()
    if(this.user && this.user.role == 'employer'){
      this.$router.push({ name: 'Talent' }).catch(()=>{})
    }

    this.getApplied()
    this.getFavorites()
  },

  created() {
    if (!firebase.auth().currentUser) {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style lang="scss">

.search-bar {
  .v-input__slot {
    background: #a4b9ab !important;
  }

  .v-label {
    color: var(--v-primary-base);
  }
}

</style>