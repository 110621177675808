<template>
  <div>
    <v-card outlined>
      <v-card-text class="pa-7">
        <h5 class="text-h5 font-weight-black primary--text">Refine Search</h5>

        <v-divider class="my-5"></v-divider>

        <div class="mb-3 d-flex justify-space-between align-center">
          <span class="body-lg heading-font font-weight-black primary--text ">Location</span>
          <v-btn
            v-if="data.region"
            @click="clearAddress()"
            absolute right
            small text
          >Clear</v-btn>
        </div>

        <div class="mb-5">
          <vue-google-autocomplete
            id="region-address"
            placeholder="Search by suburb / town / city"
            ref="regionAddress"
            classname="address-field"
            v-on:placechanged="getAddress"
            types="(regions)"
            :readonly="data.region"
            :fields="['address_components', 'geometry']"
            country="au"
          ></vue-google-autocomplete>
        </div>

        <v-divider class="my-5"></v-divider>

        <div class="mb-3 d-flex align-center">
          <span class="body-lg heading-font font-weight-black primary--text">Distance</span>
        </div>

        <div class="mb-5">
          <v-radio-group v-model="data.distance" hide-details>
            <v-radio
            v-for="(option, i) in [
              { text: 'Within 15km', value: 15 },
              { text: 'Within 50km', value: 50 },
              { text: 'Within 100km', value: 100 },
              { text: '100km +', value: 50000 },
            ]"
            :key="`distance-${i}`"
            :label="option.text" 
            :value="option.value"
          ></v-radio>
          </v-radio-group>
        </div>

        <v-divider class="my-5"></v-divider>
        
        <div class="mb-3 d-flex align-center">
          <span class="body-lg heading-font font-weight-black primary--text">School Name</span>
          <v-btn 
            v-show="data.schoolName"
            @click="$store.commit('jobSearch/clearSchoolName')" 
            absolute
            right
            x-small
          >
            Clear 
          </v-btn>
        </div>

        <div class="mb-5">
          <v-text-field
            v-model="data.schoolName"
            placeholder="School name"
            prepend-inner-icon="mdi-magnify"
            hide-details
            outlined
            dense
          ></v-text-field>
        </div>

        <v-divider class="my-5"></v-divider>
        
        <div class="mb-3 d-flex align-center">
          <span class="body-lg heading-font font-weight-black primary--text">Year Level</span>
          
          <v-btn 
            v-show="data.yearLvl.length"
            @click="$store.commit('jobSearch/clearYearLvl')" 
            absolute
            right
            x-small
          >
            Clear 
          </v-btn>
        </div>

        <div class="mb-5">
          <v-checkbox
            v-model="data.yearLvl"
            v-for="(option, i) in [
              'Early Childhood',
              'Primary',
              'Secondary',
              'Vocational'
            ]"
            :key="i"
            :label="option" 
            :value="option"
            class="ma-0"
            hide-details
          ></v-checkbox>
        </div>

        <v-divider class="my-5"></v-divider>
        
        <div class="mb-3 d-flex align-center">
          <span class="body-lg heading-font font-weight-black primary--text">School Type</span>
          <v-btn 
            v-show="data.schoolType.length"
            @click="$store.commit('jobSearch/clearSchoolType')" 
            absolute
            right
            x-small
          >
            Clear 
          </v-btn>
        </div>

        <div class="mb-5">
          <v-checkbox
            v-model="data.schoolType"
            v-for="(schoolType, i) in [
              'Private',
              'Independent',
              'Christian',
              'State',
              'Special Needs',
              'VET',
            ]"
            :key="`schoolType-${i}`"
            :label="schoolType"
            :value="schoolType"
            hide-details
            name="schoolType"
            class="ma-0"
          ></v-checkbox>
        </div>

        <v-divider class="my-5"></v-divider>

        <div class="mb-3 d-flex align-center">
          <span class="body-lg heading-font font-weight-black primary--text">Contract Length</span>
          <v-btn 
            v-show="data.contract.length"
            @click="$store.commit('jobSearch/clearContract')" 
            absolute
            right
            x-small
          >
            Clear 
          </v-btn>
        </div>

        <div class="mb-5">
        <v-checkbox
          v-model="data.contract"
          v-for="(option, i) in [
            '0-1 week',
            '1-2 weeks',
            '2-4 weeks',
            '4+ weeks',
          ]"
          :key="i"
          :label="option" 
          :value="option"
          hide-details
          class="ma-0"
        ></v-checkbox>
        </div>

        <v-divider class="my-5"></v-divider>
        
        <div class="mb-3 d-flex align-center">
          <span class="body-lg heading-font font-weight-black primary--text">Locale</span>
          <v-btn 
            v-show="data.locale.length"
            @click="$store.commit('jobSearch/clearLocale')" 
            absolute
            right
            x-small
          >
            Clear 
          </v-btn>
        </div>

        <div class="mb-5">
          <v-checkbox
            v-model="data.locale"
            v-for="(option, i) in [
              'Metro',
              'Regional City',
              'Regional Town',
              'Remote',
            ]"
            :key="`locale-${i}`"
            :label="option" 
            :value="option"
            class="ma-0"
            hide-details
          ></v-checkbox>
        </div>

        <v-divider class="my-5"></v-divider>

        <v-btn
          @click="saveDialog = true" 
          :loading="status.saving"
          color="accent gradient" 
          block
        >
          Save Search
        </v-btn>

        <v-btn
          @click="$store.commit('jobSearch/setData', null)" 
          class="mt-2"
          depressed
          block
        >
          Clear All
        </v-btn>

        <div class="mt-3">Save and retrieve favourite searches</div>
        <div>
          <a href="#" @click.prevent="showTemplates = !showTemplates">
            Saved ({{ templates.length }})
          </a>
        </div>

         <div class="mt-2" v-if="templates.length">
          <v-list max-width="220">
            <v-list-item-group v-show="showTemplates">
              <v-list-item
                v-for="template in templates"
                :key="template.id"
                @click="
                  $store.commit('jobSearch/setData', template)
                  $scrollTop()
                "
                dense
                two-line
              >
                <v-list-item-content>
                  <v-list-item-subtitle class="caption font-weight-medium">
                    {{ template.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click.stop="deleteTemplate(template)" small icon>
                    <v-icon small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-card-text>
    </v-card>

    <!-- SAVE SEARCH TEMPLATE -->
    <v-dialog
      v-model="saveDialog"
      max-width="450"
    >
      <v-card>
        <v-btn @click="closeDialog()" absolute right top icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="font-weight-bold primary--text mb-3 heading-font">
          Name This Template
        </v-card-title>
        <v-card-text>
          <v-form ref="saveForm">
            <v-text-field
              v-model="data.name"
              :rules="[rules.required]"
              outlined
            ></v-text-field>
          </v-form>

          <div>
            <v-btn
              @click="saveSearchTemplate()"
              :loading="status.saving"
              class="mr-2" 
              color="accent gradient"
            >
              Save
            </v-btn>
            <v-btn
              @click="closeDialog()" 
              :disabled="status.saving"
              text
            >
              Cancel
            </v-btn>
          </div>
        </v-card-text>
      </v-card> 
    </v-dialog>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import rules from '@/rules'

export default {
  data() {
    return {
      rules,
      saveDialog: false,
      gettingSkills: false,
      showTemplates: false,
      region: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    VueGoogleAutocomplete
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      status: state => state.jobSearch.status,
      data: state => state.jobSearch.data,
      templates: state => state.jobSearch.templates,
      skillsets: state => state.jobSearch.skillsArray
    }),
  },

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions('jobs', [
        'getAllJobs',
    ]),

    ...mapActions('jobSearch', ['saveSearch', 'getTemplates', 'deleteTemplate']),

    closeDialog() {
      this.saveDialog = false
    },

    saveSearchTemplate() {
      if(this.$refs.saveForm.validate()){
          Promise.all([this.saveSearch()])
          .then(() => {
              this.closeDialog()
              this.$scrollTop()
          })
      }
    },

    getAddress(addressData) {
      this.$store.commit('jobSearch/setRegion', addressData)
    },

    clearAddress() {
      this.$store.commit('jobSearch/setRegion', null)

      this.$nextTick(() => {
        this.$refs.regionAddress.$refs.autocomplete.value = ''
      })
    }
  },

/* -------------------------------------------------------------------------- */
/*                                   MOUNTED                                  */
/* -------------------------------------------------------------------------- */
  mounted() {
    this.getTemplates()
  }
}
</script>
