var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-7"},[_c('h5',{staticClass:"text-h5 font-weight-black primary--text"},[_vm._v("Refine Search")]),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"mb-3 d-flex justify-space-between align-center"},[_c('span',{staticClass:"body-lg heading-font font-weight-black primary--text "},[_vm._v("Location")]),(_vm.data.region)?_c('v-btn',{attrs:{"absolute":"","right":"","small":"","text":""},on:{"click":function($event){return _vm.clearAddress()}}},[_vm._v("Clear")]):_vm._e()],1),_c('div',{staticClass:"mb-5"},[_c('vue-google-autocomplete',{ref:"regionAddress",attrs:{"id":"region-address","placeholder":"Search by suburb / town / city","classname":"address-field","types":"(regions)","readonly":_vm.data.region,"fields":['address_components', 'geometry'],"country":"au"},on:{"placechanged":_vm.getAddress}})],1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"mb-3 d-flex align-center"},[_c('span',{staticClass:"body-lg heading-font font-weight-black primary--text"},[_vm._v("Distance")])]),_c('div',{staticClass:"mb-5"},[_c('v-radio-group',{attrs:{"hide-details":""},model:{value:(_vm.data.distance),callback:function ($$v) {_vm.$set(_vm.data, "distance", $$v)},expression:"data.distance"}},_vm._l(([
            { text: 'Within 15km', value: 15 },
            { text: 'Within 50km', value: 50 },
            { text: 'Within 100km', value: 100 },
            { text: '100km +', value: 50000 } ]),function(option,i){return _c('v-radio',{key:("distance-" + i),attrs:{"label":option.text,"value":option.value}})}),1)],1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"mb-3 d-flex align-center"},[_c('span',{staticClass:"body-lg heading-font font-weight-black primary--text"},[_vm._v("School Name")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.schoolName),expression:"data.schoolName"}],attrs:{"absolute":"","right":"","x-small":""},on:{"click":function($event){return _vm.$store.commit('jobSearch/clearSchoolName')}}},[_vm._v(" Clear ")])],1),_c('div',{staticClass:"mb-5"},[_c('v-text-field',{attrs:{"placeholder":"School name","prepend-inner-icon":"mdi-magnify","hide-details":"","outlined":"","dense":""},model:{value:(_vm.data.schoolName),callback:function ($$v) {_vm.$set(_vm.data, "schoolName", $$v)},expression:"data.schoolName"}})],1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"mb-3 d-flex align-center"},[_c('span',{staticClass:"body-lg heading-font font-weight-black primary--text"},[_vm._v("Year Level")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.yearLvl.length),expression:"data.yearLvl.length"}],attrs:{"absolute":"","right":"","x-small":""},on:{"click":function($event){return _vm.$store.commit('jobSearch/clearYearLvl')}}},[_vm._v(" Clear ")])],1),_c('div',{staticClass:"mb-5"},_vm._l(([
            'Early Childhood',
            'Primary',
            'Secondary',
            'Vocational'
          ]),function(option,i){return _c('v-checkbox',{key:i,staticClass:"ma-0",attrs:{"label":option,"value":option,"hide-details":""},model:{value:(_vm.data.yearLvl),callback:function ($$v) {_vm.$set(_vm.data, "yearLvl", $$v)},expression:"data.yearLvl"}})}),1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"mb-3 d-flex align-center"},[_c('span',{staticClass:"body-lg heading-font font-weight-black primary--text"},[_vm._v("School Type")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.schoolType.length),expression:"data.schoolType.length"}],attrs:{"absolute":"","right":"","x-small":""},on:{"click":function($event){return _vm.$store.commit('jobSearch/clearSchoolType')}}},[_vm._v(" Clear ")])],1),_c('div',{staticClass:"mb-5"},_vm._l(([
            'Private',
            'Independent',
            'Christian',
            'State',
            'Special Needs',
            'VET' ]),function(schoolType,i){return _c('v-checkbox',{key:("schoolType-" + i),staticClass:"ma-0",attrs:{"label":schoolType,"value":schoolType,"hide-details":"","name":"schoolType"},model:{value:(_vm.data.schoolType),callback:function ($$v) {_vm.$set(_vm.data, "schoolType", $$v)},expression:"data.schoolType"}})}),1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"mb-3 d-flex align-center"},[_c('span',{staticClass:"body-lg heading-font font-weight-black primary--text"},[_vm._v("Contract Length")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.contract.length),expression:"data.contract.length"}],attrs:{"absolute":"","right":"","x-small":""},on:{"click":function($event){return _vm.$store.commit('jobSearch/clearContract')}}},[_vm._v(" Clear ")])],1),_c('div',{staticClass:"mb-5"},_vm._l(([
          '0-1 week',
          '1-2 weeks',
          '2-4 weeks',
          '4+ weeks' ]),function(option,i){return _c('v-checkbox',{key:i,staticClass:"ma-0",attrs:{"label":option,"value":option,"hide-details":""},model:{value:(_vm.data.contract),callback:function ($$v) {_vm.$set(_vm.data, "contract", $$v)},expression:"data.contract"}})}),1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"mb-3 d-flex align-center"},[_c('span',{staticClass:"body-lg heading-font font-weight-black primary--text"},[_vm._v("Locale")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.locale.length),expression:"data.locale.length"}],attrs:{"absolute":"","right":"","x-small":""},on:{"click":function($event){return _vm.$store.commit('jobSearch/clearLocale')}}},[_vm._v(" Clear ")])],1),_c('div',{staticClass:"mb-5"},_vm._l(([
            'Metro',
            'Regional City',
            'Regional Town',
            'Remote' ]),function(option,i){return _c('v-checkbox',{key:("locale-" + i),staticClass:"ma-0",attrs:{"label":option,"value":option,"hide-details":""},model:{value:(_vm.data.locale),callback:function ($$v) {_vm.$set(_vm.data, "locale", $$v)},expression:"data.locale"}})}),1),_c('v-divider',{staticClass:"my-5"}),_c('v-btn',{attrs:{"loading":_vm.status.saving,"color":"accent gradient","block":""},on:{"click":function($event){_vm.saveDialog = true}}},[_vm._v(" Save Search ")]),_c('v-btn',{staticClass:"mt-2",attrs:{"depressed":"","block":""},on:{"click":function($event){return _vm.$store.commit('jobSearch/setData', null)}}},[_vm._v(" Clear All ")]),_c('div',{staticClass:"mt-3"},[_vm._v("Save and retrieve favourite searches")]),_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showTemplates = !_vm.showTemplates}}},[_vm._v(" Saved ("+_vm._s(_vm.templates.length)+") ")])]),(_vm.templates.length)?_c('div',{staticClass:"mt-2"},[_c('v-list',{attrs:{"max-width":"220"}},[_c('v-list-item-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTemplates),expression:"showTemplates"}]},_vm._l((_vm.templates),function(template){return _c('v-list-item',{key:template.id,attrs:{"dense":"","two-line":""},on:{"click":function($event){_vm.$store.commit('jobSearch/setData', template)
                _vm.$scrollTop()}}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"caption font-weight-medium"},[_vm._v(" "+_vm._s(template.name)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteTemplate(template)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1)}),1)],1)],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.saveDialog),callback:function ($$v) {_vm.saveDialog=$$v},expression:"saveDialog"}},[_c('v-card',[_c('v-btn',{attrs:{"absolute":"","right":"","top":"","icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-card-title',{staticClass:"font-weight-bold primary--text mb-3 heading-font"},[_vm._v(" Name This Template ")]),_c('v-card-text',[_c('v-form',{ref:"saveForm"},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"outlined":""},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('div',[_c('v-btn',{staticClass:"mr-2",attrs:{"loading":_vm.status.saving,"color":"accent gradient"},on:{"click":function($event){return _vm.saveSearchTemplate()}}},[_vm._v(" Save ")]),_c('v-btn',{attrs:{"disabled":_vm.status.saving,"text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }